import { dew as _utilDew } from "./util";
import { dew as _browserCryptoLibDew } from "./browserCryptoLib";
import _buffer from "buffer/";
import _url from "url/";
import _querystring from "querystring/";
import { dew as _browserClockDew } from "./realclock/browserClock";
import { dew as _bufferedCreateEventStreamDew } from "./event-stream/buffered-create-event-stream";
import * as _core from "./core";
import { dew as _credentialsDew } from "./credentials";
import { dew as _credential_provider_chainDew } from "./credentials/credential_provider_chain";
import { dew as _temporary_credentialsDew } from "./credentials/temporary_credentials";
import { dew as _chainable_temporary_credentialsDew } from "./credentials/chainable_temporary_credentials";
import { dew as _web_identity_credentialsDew } from "./credentials/web_identity_credentials";
import { dew as _cognito_identity_credentialsDew } from "./credentials/cognito_identity_credentials";
import { dew as _saml_credentialsDew } from "./credentials/saml_credentials";
import { dew as _browser_parserDew } from "./xml/browser_parser";
import { dew as _xhrDew } from "./http/xhr";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var util = _utilDew(); // browser specific modules


  util.crypto.lib = _browserCryptoLibDew();
  util.Buffer = _buffer.Buffer;
  util.url = _url;
  util.querystring = _querystring;
  util.realClock = _browserClockDew();
  util.environment = "js";
  util.createEventStream = _bufferedCreateEventStreamDew().createEventStream;

  util.isBrowser = function () {
    return true;
  };

  util.isNode = function () {
    return false;
  };

  var AWS = _core.__dew ? _core.__dew() : _core.default;
  /**
   * @api private
   */

  exports = AWS;

  _credentialsDew();

  _credential_provider_chainDew();

  _temporary_credentialsDew();

  _chainable_temporary_credentialsDew();

  _web_identity_credentialsDew();

  _cognito_identity_credentialsDew();

  _saml_credentialsDew(); // Load the DOMParser XML parser


  AWS.XML.Parser = _browser_parserDew(); // Load the XHR HttpClient

  _xhrDew();

  if (typeof process === "undefined") {
    var process = {
      browser: true
    };
  }

  return exports;
}